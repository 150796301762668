body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
	height:100%;
	width:100%
}

.yi-button {
    background-color: #ecc14e;
    border-radius: 40px;
    color: black;
    padding: 10px 20px;
    text-decoration: none;
    text-transform: uppercase;
}

.yi-button:hover {
    background-color: #c99e40
}

/** Your Invited logo animation */

.yi-logo-animation {
    height:64px;
    overflow:hidden;
    margin-left: 1rem;
}

.yi-logo-animation div:first-child {
    animation: yi-logo-animation 8s infinite;
}

.yi-logo-animation .tijdprikker {
    height: 50px;
    padding: 4px 20px;
}
.yi-logo-animation .yourinvited {
    height: 50px;
    padding: 18px 20px;
}

@keyframes yi-logo-animation {
    0% {margin-top: 0;}
    10% {margin-top: 0;}
    20% {margin-top: -64px;}
    30% {margin-top: -64px;}
    40% {margin-top: -64px;}
    60% {margin-top: -64px;}
    70% {margin-top: -64px;}
    80% {margin-top: 0;}
    90% {margin-top: 0;}
    100% {margin-top: 0;}
}

/** You're invited text marquee */

/* .yi-text-marquee {
    animation: yi-text-marquee 10s linear infinite;
    overflow:hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 40px;
} */

.yi-text-marquee {
    border: 3px solid black;
    border-radius: 5px;
    overflow: hidden;
}

.yi-text-marquee div {
    /* animation properties */
    -moz-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    transform: translateX(0px);

    -moz-animation: yi-marquee 100s linear infinite;
    -webkit-animation: yi-marquee 100s linear infinite;
    animation: yi-marquee 100s linear infinite;
}

.yi-text-marquee .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

/* for Firefox */
@-moz-keyframes yi-marquee {
    from { -moz-transform: translateX(900px); }
    to { -moz-transform: translateX(-100%); }
}

/* for Chrome */
@-webkit-keyframes yi-marquee {
    from { -webkit-transform: translateX(900px); }
    to { -webkit-transform: translateX(-100%); }
}

@keyframes yi-marquee {
    from {
        -moz-transform: translateX(900px);
        -webkit-transform: translateX(900px);
        transform: translateX(900px);
    }
    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}